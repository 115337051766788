.privacy_policy {
    color: black;
    width: 75%;
    margin: auto;
    padding: 3rem;
}

.privacy_policy h1 {
    font-size: 2.5rem;
    font-weight: 500;
}

.privacy_policy p {
    font-size: 0.9rem;
}

.privacy_policy p {
    width: 80%;
    font-size: 15px;
}