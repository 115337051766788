body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family:"Font1";
  src: url("../fonts/Font1.eot"), local("Font1") format("eot");
}

@font-face{
  font-family:"Font2";
  src: url("../fonts/Font2.woff2"), local("Font2") format("woff2");
}

@font-face{
  font-family:"Font3";
  src: url("../fonts/Font3.woff2"), local("Font3") format("woff2");
}

::placeholder {
  color: white;
  font-style: italic;
  font-weight: 700;
}