.home {
    background-color: rgb(56,74,211);
}

.navigation {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(19, 25, 70);
    width: 100%;
}

.navigation ul {
    margin-right: 15rem;
    text-transform: uppercase;
    list-style: none;
    display: flex;
}

.navigation ul a {
    color: white;
    text-decoration: none;
}

.navigation li {
    margin: 0.5rem;
    cursor: pointer;
    user-select: none;
    font-size: 0.9rem;
}

.navigation h1 {
    font-size: 2rem;
    font-weight: 600;
    padding-right: 3rem;
}

.top_section {
    padding: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45%;
    margin: auto;
    padding-right: 10rem;
}

.top_section .quote {
    font-weight: 700;
    text-align: center;
    font-size: 19px;
}

.top_section .description {
    text-align: center;
    font-size: 17px;
}

.top_section .right_container {
    margin-left: 2rem;
}

.games_section .header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: rgb(19, 25, 70);
    padding: 3rem 0 3rem 0;
    font-size: 2.4rem;
    font-weight: 700;
}

.games_section .game {
    width: 100%;
    height: 625px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.games_section .wrapper {
    width: 50%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.games_section .game .right_container {
    margin-left: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 450px;
}

.games_section .game p {
    text-align: center;
}

.games_section .game img {
    max-width: 250px;
    max-height: 500px;
}

.games_section .game a {
    margin-top: 1.8rem;
}

.career_section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    background-color: rgb(19, 25, 70);
    padding-bottom: 3rem;
}

.career_section h1 {
    font-size: 2.4rem;
    font-weight: 500;
}

.career_section p {
    text-align: center;
    max-width: 500px;
}

.contact_section {
    padding: 3rem 0 3rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.contact_section .form {
    width: 45%;
}

.contact_section h1 {
    font-size: 2.4rem;
    font-weight: 500;
}   

.contact_section input, .contact_section textarea {
    font: italic;
    border-radius: 20px;
    background-color: rgb(56,74,211);
    border-color: rgba(227,227,227,1);
    box-sizing: border-box;
    border-width: 2px;
    border-style: solid;
    padding: 3px 3px 3px 24px;
    margin: 1rem;
    min-height: 51px;
    color: white;
    outline: none;
}

.contact_section input:hover, .contact_section textarea:hover {
    border-color: black;
    background-color: rgb(255,255,255,0.28);
}

.contact_section input {
    width: 45%;
}

.contact_section textarea {
    width: 95%;
    height: 100px;
    padding-top: 0.5rem;
}

.contact_section button {
    border: none;
    border-radius: 20px;
    background-color: white;
    cursor: pointer;
    padding: 1rem 4rem;
    transition: all .2s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.contact_section button:hover {
    background-color: black;
    color: white;
}

.contact_section .button_container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 1rem;
}

.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(19, 25, 70);
    width: 100%;
    font-size: 0.9rem;
}

.footer ul {
    list-style: none;
    display: flex;
    margin-left: 20rem;
}

.footer li {
    margin: 0.5rem;
}

.footer a {
    color: white;
}

.hamburger span {
    width: 30px;
    height: 2px;
    margin: 0.35rem;
    background-color: white;
    display: block;
}

@media screen and (max-width: 768px) {
    .navigation ul {
        margin-right: 2rem;
    }
    
    .navigation {
        padding: 1rem 0 1rem 0;
    }

    .top_section {
        flex-direction: column;
        padding: 0;
    }

    .top_section .right_container {
        margin-left: 0;
    }

    .games_section .wrapper {
        flex-direction: column;
        padding-top: 2rem;
        width: 90%;
    }

    .games_section .game img {
        max-width: 125px;
        max-height: 250px;
    }

    .games_section .game .right_container {
        margin-left: 0;
    }

    .contact_section input {
        width: 100%;
    }
    
    .contact_section textarea {
        width: 100%;
        height: 100px;
        padding-top: 0.5rem;
    }

    .contact_section .form {
        width: 80%;
    }

    .contact_section .button_container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 0;
    }
    
    .footer ul {
        margin-left: 0;
    }

    .footer p {
        margin-left: 1rem;
    }
}